.TemplateHolder {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.DocumentHolder {
    position: relative;
    width: 100%;
    min-height: 78.63vw;
}

.hr-line {
    border-bottom: 1px solid black;
}

.text-uppercase {
    text-transform: uppercase;
}

@font-face {
    font-family: "micr-normal";
    src: local("micr-normal"), url("../../../Assets/fonts/micr-encoding.regular.ttf") format("truetype");
    font-weight: normal;
}

.micr-font {
    font-family: "micr-normal";
    font-size: 2vw;
}

.payToText {
    font-size: 0.95vw !important;
}

.employeeNameText {
    font-size: 1.55vw !important;
}

@media print {
    .micr-font {
        font-size: 3.8vw;
    }

    .payToText {
        font-size: 1.5vw !important;
    }

    .employeeNameText {
        font-size: 2.55vw !important;
    }
}
