:root {
  /* --button-theme: #54a3eb; */
  --app-theme: #333b4e;
  /* --text-theme: #5a64d7;
  --border-theme: #5a64d7; */
}

.bg-app {
  background-color: var(--app-theme);
}

.dk-bg-button,
.bg-button {
  background-color: var(--app-theme) !important;
}

/* .border-app {
  border-style: solid;
  border-color: var(--border-theme);
}

.text-app {
  color: var(--text-theme);
}
 */
